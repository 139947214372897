// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import type { ItemData, TotalData } from "../../services/dashboardApi";
import type { Column } from "../TableComponent/types/TableComponent.types";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import { TableComponent, TablePagination } from "../TableComponent";

type Props = {
  data: ItemData[],
  totalData: TotalData,
  page: number,
  total: number,
  pageSize: number,
  fields: string[],
  filters: Filters,
  onChangeTablePage: (string, number) => mixed,
  onChangeTablePageSize: (string, number) => mixed,
  onGetData: () => mixed,
}

const TransactionReportTable: StatelessFunctionalComponent<Props> = ({
  data,
  totalData,
  page,
  total,
  pageSize,
  fields,
  filters,
  onChangeTablePage,
  onChangeTablePageSize,
  onGetData,
}: Props) => {
  const columns: Array<Column<ItemData>> = [
    {
      label: "Date",
      field: "date",
      key: "date",
    },
    {
      label: "Company Name",
      field: "customer",
      key: "customer",
    },
    {
      label: "Transaction Id",
      field: "invoiceId",
      key: "invoiceId",
    },
    {
      label: "Department",
      field: "department",
      key: "department",
    },
    {
      label: "Country",
      field: "country",
      key: "country",
    },
    {
      label: "Platform",
      field: "platform",
      key: "platform",
    },
    {
      label: "Age Group",
      field: "age",
      key: "age",
    },
    {
      label: "Leads",
      field: "quantity",
      key: "quantity",
    },
    {
      label: "Commission",
      field: "cost",
      key: "cost",
    },
    {
      label: "Total Commission",
      field: "amount",
      key: "amount",
    },
  ].filter((column: Column<ItemData>): boolean => fields.includes(column.field));

  const totalColumns: Array<Column<TotalData>> = [
    {
      label: "",
      field: "",
      key: "summary",
      render: (): string => "Summary",
    },
    {
      label: "",
      field: "",
      key: "quantity",
      render: ({ row }: { row: TotalData }): string => `${String(filters.currency) || "$"} ${row.amount}`,
      colSpan: columns.length - 1,
    },
  ];

  const handlePageChange = (newPage: number) => {
    onChangeTablePage(TRANSACTION_TABLE, newPage);
    onGetData();
  };

  const handlePageSizeChange = (newPageSize: number) => {
    onChangeTablePage(TRANSACTION_TABLE, 1);
    onChangeTablePageSize(TRANSACTION_TABLE, newPageSize);
    onGetData();
  };

  return (
    <>
      <TableComponent
        data={data}
        columns={columns}
        totalData={totalData}
        totalColumns={totalColumns}
      />
      <TablePagination
        data-testid="pagination"
        page={page}
        pageSize={pageSize}
        count={total}
        onPageChange={handlePageChange}
        onChangePageSize={handlePageSizeChange}
      />
    </>
  );
};

export default TransactionReportTable;
