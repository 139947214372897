// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Box,
  Button,
} from "@mui/material";
import {
} from "@mui/icons-material";
import type { DateRangeFooterProps } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";

const FooterComponent: StatelessFunctionalComponent<DateRangeFooterProps> = ({
  onApply,
}: DateRangeFooterProps) => (
  <Box p={2} display="flex" justifyContent="flex-end">
    <Button
      size="large"
      color="secondary"
      variant="contained"
      onClick={onApply}
    >
      Apply
    </Button>
  </Box>
);

export default FooterComponent;
