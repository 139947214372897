// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import GetApp from "@mui/icons-material/GetApp";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import type { ItemData, TotalData } from "../../services/dashboardApi";
import { makeStylesTyped } from "../../helpers/generators";
import { setQueryFilter } from "../../helpers/queryFilter";
import TransactionReportFilters from "./TransactionReportFilters";
import TransactionReportTable from "./TransactionReportTable";

type Props = {|
  data: ItemData[],
  totalData: TotalData,
  page: number,
  total: number,
  pageSize: number,
  filters: Filters,
  loading: boolean,
  onChangeTablePage: (string, number) => mixed,
  onChangeTableFilters: (string, Filters) => mixed,
  onChangeTablePageSize: (string, number) => mixed,
  onGetData: () => mixed,
  onDownloadTransactionReport: () => mixed,
  departmentsDropdown: DropDownObjItemType[],
  countriesDropdown: DropDownObjItemType[],
  customersDropdown: DropDownObjItemType[],
  platformsDropdown: DropDownObjItemType[],
  agesDropdown: DropDownObjItemType[],
  onGetCountriesDropdownData: () => mixed,
  onGetPlatformsDropdownData: () => mixed,
  onGetAgesDropdownData: () => mixed,
  setIsAdvancedFilterEnabled: (boolean) => mixed,
  isAdvancedFilterEnabled: boolean,
  fields: string[],
|}

type Classes = {
  download: string,
  downloadIcon: string,
}

const useStyles: () => Classes = makeStylesTyped(() => ({
  download: {
    width: "320px",
  },
  downloadIcon: {
    marginLeft: "60px",
    marginRight: "-80px",
  },
}));

const TransactionReport: StatelessFunctionalComponent<Props> = ({
  data,
  totalData,
  page,
  total,
  pageSize,
  filters,
  loading,
  onChangeTablePage,
  onChangeTableFilters,
  onChangeTablePageSize,
  onGetData,
  onDownloadTransactionReport,
  departmentsDropdown,
  countriesDropdown,
  customersDropdown,
  platformsDropdown,
  agesDropdown,
  onGetCountriesDropdownData,
  onGetPlatformsDropdownData,
  onGetAgesDropdownData,
  setIsAdvancedFilterEnabled,
  isAdvancedFilterEnabled,
  fields,
}: Props) => {
  const classes: Classes = useStyles();

  useEffect(() => {
    if (data.length === 0) {
      onGetData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onGetCountriesDropdownData();
    onGetPlatformsDropdownData();
    onGetAgesDropdownData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setQueryFilter(filters);
  }, [filters]);

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width={1} pb={2} display="flex" justifyContent="space-between">
          <Typography variant="h5">Transaction Report</Typography>
          <Box>
            <Button
              className={classes.download}
              variant="contained"
              color="secondary"
              name="download"
              disabled={loading}
              endIcon={<GetApp className={classes.downloadIcon} />}
              onClick={onDownloadTransactionReport}
            >
              Download CSV
            </Button>
          </Box>
        </Box>
        <TransactionReportFilters
          filters={filters}
          loading={loading}
          onChangeTableFilters={onChangeTableFilters}
          onGetData={onGetData}
          departmentsDropdown={departmentsDropdown}
          countriesDropdown={countriesDropdown}
          customersDropdown={customersDropdown}
          platformsDropdown={platformsDropdown}
          agesDropdown={agesDropdown}
          setIsAdvancedFilterEnabled={setIsAdvancedFilterEnabled}
          isAdvancedFilterEnabled={isAdvancedFilterEnabled}
        />
      </Grid>
      <Grid item xs={12}>
        { loading && (
          <Box width={1} mt={3} p={3} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        { isShowNoDataMessage && (
          <Box width={1} mt={3} p={3} display="flex" justifyContent="center">
            <Typography>Nothing to show</Typography>
          </Box>
        )}
        { isShowData && (
          <Box component={Paper} mt={3} p={3}>
            <TransactionReportTable
              data={data}
              totalData={totalData}
              page={page}
              total={total}
              pageSize={pageSize}
              fields={fields}
              filters={filters}
              onChangeTablePage={onChangeTablePage}
              onChangeTablePageSize={onChangeTablePageSize}
              onGetData={onGetData}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default TransactionReport;
