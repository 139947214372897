// @flow
import React, {
  type StatelessFunctionalComponent,
  useEffect,
  useState,
} from "react";
import {
  Box, Typography,
} from "@mui/material";
import ls from "local-storage";
import type { EventPostbackType } from "@fas/cpa-cabinet-ui/lib/EventPostback/EventPostback.types";
import CollapseCard from "./CollapseCard";
import EventPostbackComponent from "../EventPostbackComponent";
import InputCode from "./InputCode";
import HowToCreatePostback from "./HowToCreatePostback";

type Props = {}

export const usePrevious = <T>(value: T) => {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const PostbackUrls: StatelessFunctionalComponent<Props> = ({}: Props) => {
  const [tracking, setTracking] = useState({
    isEditable: false,
    value: "",
  });
  const [eventPostbacks, setEventPostback] = useState<EventPostbackType[]>([]);
  const prevEventPostbacks = usePrevious(eventPostbacks);
  const prevTracking = usePrevious(tracking);

  useEffect(() => {
    setEventPostback(ls.get("postbacks") || []);
    setTracking(ls.get("tracking") || tracking);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prevEventPostbacks !== eventPostbacks) {
      // $FlowFixMe
      ls.set("postbacks", eventPostbacks);
    }
    if (prevTracking !== tracking) {
      ls.set("tracking", tracking);
    }
  }, [eventPostbacks, tracking]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" pb={2}>
        <Typography variant="h5">Postback URLs</Typography>
      </Box>

      <InputCode tracking={tracking} onChange={setTracking} />

      <Box p={2} />
      <CollapseCard title="Informational events postback" my={3}>
        <Box p={3}>
          <EventPostbackComponent
            items={eventPostbacks}
            onChange={setEventPostback}
          />
        </Box>
      </CollapseCard>
      <Box p={2} />
      <HowToCreatePostback />
    </Box>
  );
};

export default PostbackUrls;
