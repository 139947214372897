// @flow
/* eslint-disable max-len */
import React, { type StatelessFunctionalComponent } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CollapseCard from "./CollapseCard";
import { TableComponent } from "../TableComponent";

type Props = {
}

const data = [
  {
    parameter: "{payout}",
    description: "Amount paid to you for conversion",
    example: "1.95",
  },
  {
    parameter: "{country}",
    description: "Country name of transaction",
    example: "",
  },
  {
    parameter: "{platform}",
    description: "Platform of transaction",
    example: "",
  },
  {
    parameter: "{data2}",
    description: "Your click/transaction ID",
    example: "",
  },
  {
    parameter: `{Date}

{Time}

{DateTime}`,
    description: "Datetime of transaction",
    example: "04/20/19 07:14:59",
  },
  {
    parameter: "{utm_content}",
    description: "Additional parameter (to mark your sources/any additional information)",
    example: "",
  },
  {
    parameter: "{s3}",
    description: "Your own additional parameter 3",
    example: "",
  },
  {
    parameter: "{conversionType}",
    description: "Transaction type (\"lead\", \"sale\", \"install\", \"confirm\")",
    example: "lead",
  },
  {
    parameter: "{tds_cid}",
    description: "Internal click_id",
    example: "",
  },
  {
    parameter: "{payout_in_currency}",
    description: "Amount paid to you for conversion in offer currency",
    example: "",
  },
  {
    parameter: "{currency}",
    description: "Offer currency",
    example: "",
  },
];

const useStyles = makeStyles((theme) => ({
  urlStyle: {
    color: theme.palette.primary.main,
  },
  cell: {
    textAlign: "left",
    verticalAlign: "initial",
  },
  headerCell: {
    textAlign: "left",
  },
  parameter: {
    color: theme.palette.text.subtitle,
    "& pre": {
      margin: 1,
    },
  },
}));

const HowToCreatePostback: StatelessFunctionalComponent<Props> = ({}: Props) => {
  const classes = useStyles();

  const columns = [
    {
      field: "parameter",
      label: "Parameter",
      key: "parameter",
      className: classes.parameter,
      render: ({ row }) => <pre>{row.parameter}</pre>,
    },
    {
      field: "description",
      label: "Description",
      key: "description",
    },
    {
      field: "example",
      label: "Example",
      key: "example",
    },
  ];

  return (
    <CollapseCard title="How to create own postback URLs">
      <Box p={3}>
        <Box mb={2}>
          <Typography>
            Here you can create your own postback URLs that will be sent any time you have a new transaction.
          </Typography>
          <Typography>
            The example of postback URL:
            <span className={classes.urlStyle}>{"http://your-domain.com/tracking.php?payout={event.s1}&sub_id={hit.data2}"}</span>
          </Typography>
          <Typography>
            After creating the postback URL, ask your manager to approve it. If the code is not approved, it will not work.
          </Typography>
        </Box>
        <TableComponent
          classes={classes}
          columns={columns}
          data={data}
        />
      </Box>
    </CollapseCard>
  );
};

export default HowToCreatePostback;
