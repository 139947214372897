// @flow
import React from "react";

const Logo = () => (
  <svg width="167" height="34" viewBox="0 0 167 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.0143123C0 3.8784 3.47768 6.99829 7.18434 6.99829H11.7497C11.0771 8.05734 10.5189 9.21657 10.0609 10.476C9.35968 12.4366 8.98758 14.5547 8.93034 16.8159C8.93034 16.9734 8.95896 18.7909 9.0162 19.4492C9.0162 19.4492 9.23088 21.6961 10.0609 24.1577C10.3901 25.1595 10.7479 25.9466 11.2345 26.8196C12.5798 25.5173 14.4832 25.088 16.358 25.088H18.1755C18.1612 25.0736 18.1469 25.0593 18.1326 25.045C17.3311 24.1434 16.5583 22.8983 16.129 21.6246C15.6996 20.3509 15.485 18.9054 15.485 17.3025C15.4993 15.714 15.6996 14.2828 16.129 13.0234C16.5583 11.7354 17.1737 10.6477 17.9752 9.74609C18.7766 8.85878 19.7498 8.17183 20.8947 7.69955C21.9251 7.27021 23.07 7.04123 24.3437 6.99829V6.98398H33.4888C37.1954 6.98398 40.3726 3.84978 40.3726 0H0V0.0143123Z" fill="#333333" />
    <path d="M39.6713 9.67439C39.3135 8.6869 38.9844 7.99995 38.4978 7.12695C37.1525 8.42929 35.2491 8.85864 33.3743 8.85864H31.7428H31.6426C32.444 9.76026 33.1453 10.9338 33.5746 12.2218C34.004 13.5099 34.2186 14.9553 34.2186 16.5725C34.2186 18.204 34.004 19.6638 33.5746 20.9518C33.1453 22.2398 32.5299 23.3275 31.7285 24.2291C30.927 25.1164 29.9538 25.8034 28.8089 26.2757C27.7785 26.705 26.6336 26.934 25.3599 26.9769V26.9912H16.0574C12.3508 26.9912 9.33105 30.1254 9.33105 33.9752H25.3456C27.5495 33.918 29.5674 33.4886 31.3993 32.6729C33.36 31.7999 35.0344 30.5834 36.4226 29.0378C37.8108 27.4778 38.8842 25.646 39.6427 23.5135C40.4012 21.3811 40.7733 19.077 40.7733 16.5868C40.8019 14.0823 40.4298 11.7925 39.6713 9.67439Z" fill="url(#paint0_linear)" />
    <path d="M51.1492 26.9911V10.5186H48.3871C46.4121 10.5186 44.7949 8.93006 44.752 6.95508H61.8541C61.8255 8.93006 60.2083 10.5186 58.219 10.5186H55.5571V26.9911H51.1492Z" fill="#333333" />
    <path d="M64.859 27.349C62.6121 27.349 60.8088 26.6763 59.5208 25.3454C58.2328 24.0144 57.5745 22.1969 57.5745 19.907C57.5745 17.5599 58.2471 15.6852 59.5924 14.3542C60.9233 13.0232 62.7552 12.3506 65.045 12.3506C67.2919 12.3506 69.0665 13.0232 70.3403 14.3542C71.614 15.6852 72.258 17.4598 72.258 19.6494C72.258 22.0108 71.5854 23.8999 70.283 25.2738C68.9807 26.6477 67.1488 27.349 64.859 27.349ZM64.9735 15.6422C63.7856 15.6422 62.8554 16.0143 62.1971 16.7442C61.5387 17.4741 61.2096 18.5188 61.2096 19.8498C61.2096 22.6548 62.4833 24.0717 65.0164 24.0717C67.4207 24.0717 68.6515 22.6119 68.6515 19.7353C68.6372 17.0161 67.4064 15.6422 64.9735 15.6422Z" fill="#333333" />
    <path d="M73.7036 33.6175V12.7228H75.65C76.7663 12.7228 78.0114 13.6244 78.0114 14.8266V15.1558V15.1701C78.0114 15.1701 78.269 14.7407 78.3978 14.569C79.4282 13.1951 80.8593 12.5225 82.7628 12.5225C84.566 12.5225 85.8826 13.1235 86.8988 14.4116C87.9292 15.7139 88.4158 17.3168 88.4158 19.4635C88.4158 21.8392 87.829 23.7712 86.6555 25.2024C85.4533 26.6765 84.0078 27.3634 82.1044 27.3634C80.4729 27.3634 79.1992 26.748 78.3119 25.5316C78.2403 25.4314 78.0114 25.1165 78.0114 25.1165L78.0257 25.9323V33.6461H73.7036V33.6175ZM81.4318 15.7568C80.4157 15.7568 79.557 16.1003 78.913 16.8016C78.3262 17.4313 77.9398 18.6621 77.9398 19.9215V20.68C77.9398 21.6675 78.2403 22.4975 78.8271 23.1272C79.4139 23.7569 80.201 24.0718 81.1599 24.0718C82.2905 24.0718 83.1921 23.6711 83.8218 22.8839C84.4515 22.0968 84.7664 20.9805 84.7664 19.5637C84.7807 17.0449 83.6501 15.7568 81.4318 15.7568Z" fill="#333333" />
    <path d="M100.137 27.3494C97.2034 27.3494 94.7848 26.4049 92.9529 24.5444C91.1068 22.6839 90.1765 20.2223 90.1765 17.2456C90.1765 14.0827 91.1211 11.5067 92.9959 9.56031C94.8707 7.61395 97.3895 6.62646 100.466 6.62646C103.386 6.62646 105.776 7.57102 107.579 9.43151C109.382 11.292 110.298 13.7822 110.298 16.8448C110.298 19.979 109.354 22.5408 107.479 24.4728C105.604 26.3762 103.143 27.3494 100.137 27.3494ZM100.352 10.0755C98.563 10.0755 97.1176 10.7195 96.0585 11.9789C94.9995 13.2383 94.4699 14.9271 94.4699 17.0023C94.4699 19.106 94.9995 20.7948 96.0585 22.0113C97.1176 23.242 98.5201 23.8574 100.223 23.8574C101.983 23.8574 103.4 23.2564 104.431 22.0542C105.461 20.8663 105.991 19.1919 105.991 17.0738C105.991 14.8699 105.49 13.1382 104.488 11.9217C103.472 10.7052 102.084 10.0755 100.352 10.0755Z" fill="url(#paint1_linear)" />
    <path d="M138.506 27.3488C136.202 27.3488 134.399 26.7048 133.139 25.4311C131.88 24.1574 131.236 22.3541 131.236 20.0786C131.236 17.7172 131.923 15.8138 133.297 14.4399C134.671 13.066 136.374 12.3647 138.377 12.3647C140.338 12.3647 141.583 12.88 142.799 14.2109C143.873 15.3701 144.374 16.973 144.374 19.2485V21.0518H135.501V21.1233C135.658 23.2414 137.003 24.3291 139.522 24.3291C140.123 24.3291 140.739 24.2719 141.325 24.1717C141.454 24.1431 141.597 24.1287 141.726 24.1287C142.928 24.1287 143.901 25.1019 143.901 26.2898V26.3041C142.613 26.9767 140.696 27.3488 138.506 27.3488ZM138.334 15.227C137.705 15.227 136.76 15.4703 136.173 16.143C135.687 16.7011 135.601 17.3881 135.501 18.1752L135.458 18.4614H140.91V18.3899C140.91 16.3004 140.037 15.227 138.334 15.227Z" fill="url(#paint2_linear)" />
    <path d="M145.948 26.9913V12.7228H150.256V15.2702H150.428L150.442 15.2273C151.115 13.3525 152.303 12.4795 154.177 12.4795C154.607 12.4795 154.964 12.5224 155.208 12.6226V14.4258C155.208 15.3847 154.406 16.1718 153.433 16.1718H153.419C152.503 16.1718 151.701 16.6012 151.086 17.3883C150.571 18.061 150.256 19.2631 150.256 20.5798V26.9913H145.948Z" fill="url(#paint3_linear)" />
    <path d="M130.42 8.72996C130.921 8.72996 131.45 8.83013 131.994 9.03049V5.71024C131.407 5.53851 130.678 5.45264 129.805 5.45264C127.987 5.45264 126.542 5.95354 125.482 6.94103C124.438 7.92851 123.894 9.24517 123.894 10.8624V12.7228H118.341V11.12C118.341 9.53139 119.085 8.72996 120.545 8.72996C121.046 8.72996 121.575 8.83013 122.119 9.03049V5.71024C121.533 5.53851 120.803 5.45264 119.93 5.45264C118.112 5.45264 116.667 5.95354 115.608 6.94103C114.563 7.92851 114.019 9.24517 114.019 10.8624V12.7228H111.6H110.856C110.885 14.1969 111.915 15.4277 113.289 15.7712C113.49 15.8284 113.718 15.8714 113.947 15.8714H114.019V27.0057H118.312V15.8571H123.865V26.9913H128.159V15.8571H128.245C129.991 15.8571 131.422 14.4545 131.45 12.7085H128.159V11.1057C128.216 9.53139 128.96 8.72996 130.42 8.72996Z" fill="url(#paint4_linear)" />
    <path d="M155.952 26.4905V25.503C155.952 24.3151 156.624 23.5996 157.755 23.757C157.955 23.7856 158.156 23.8428 158.342 23.9144C158.9 24.1291 160.66 24.7302 162.048 24.0861C162.549 23.8572 162.764 23.4851 162.764 23.0414C162.764 22.3258 161.948 21.9681 161.619 21.8249C161.29 21.6818 159.801 21.2095 159.444 21.0664C158.871 20.8518 156.925 20.1219 156.309 18.4045C156.166 17.9752 155.351 15.0986 157.984 13.4384C158.556 13.0807 159.916 12.3794 162.32 12.3794C162.95 12.3794 164.181 12.4366 166.113 13.0091L166.027 14.2113C165.984 14.9555 165.483 15.5995 164.739 15.5995C164.624 15.5995 164.367 15.5708 164.367 15.5708C163.723 15.3991 160.403 14.7837 160.403 16.5726C160.403 16.8446 160.474 17.3741 161.347 17.832C161.905 18.1183 163.766 18.6764 164.739 19.1487C167 20.2364 167 22.0825 167 22.6836C167 26.1184 163.68 26.9484 162.893 27.1202C162.134 27.2633 159.601 27.7928 155.952 26.4905Z" fill="url(#paint5_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="44.7007" y1="11.1975" x2="11.2076" y2="36.0879" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EE2F42" />
        <stop offset="1" stopColor="#F05223" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="172.093" y1="28.622" x2="87.1649" y2="14.8499" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EE2F42" />
        <stop offset="1" stopColor="#F05223" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="171.35" y1="25.2072" x2="88.4095" y2="11.7575" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EE2F42" />
        <stop offset="1" stopColor="#F05223" />
      </linearGradient>
      <linearGradient id="paint3_linear" x1="171.675" y1="23.2044" x2="88.7346" y2="9.75465" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EE2F42" />
        <stop offset="1" stopColor="#F05223" />
      </linearGradient>
      <linearGradient id="paint4_linear" x1="171.513" y1="24.1976" x2="88.5731" y2="10.7479" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EE2F42" />
        <stop offset="1" stopColor="#F05223" />
      </linearGradient>
      <linearGradient id="paint5_linear" x1="171.94" y1="21.5663" x2="89" y2="8.11659" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EE2F42" />
        <stop offset="1" stopColor="#F05223" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;
