// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import { Box, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PowerSettingsNew, Menu, Sort } from "@mui/icons-material";
import { LogoLight } from "../../icons";
import type { Props } from "./types/Header.types";

const useStyles = makeStyles((theme) => ({
  layout: {
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  logo: {
    margin: "auto",
  },
  menu: {
    color: "rgba(0, 0, 0, 0.3)",
  },
}));

const Header: StatelessFunctionalComponent<Props> = ({
  isExpand, onToggleExpand, isShowAction, onLogout,
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes && classes.layout}>
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {isShowAction && (
            <IconButton name="menu" onClick={onToggleExpand} className={classes && classes.menu} size="small">
              {isExpand ? <Menu /> : <Sort />}
            </IconButton>
          )}
          <Box className={classes && classes.logo} display="flex" alignItems="center" mr={1}><LogoLight /></Box>

          {isShowAction && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box
                data-testid="action_icons"
                className={classes && classes.iconsRoot}
                mr={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton name="logout" onClick={onLogout} className={classes && classes.icons} size="small">
                  <PowerSettingsNew />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Header;
