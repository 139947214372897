/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useEffect,
  type StatelessFunctionalComponent,
} from "react";
import {
  Grid,
  Paper,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import Chart, { formatDate } from "../Chart";
import { TableComponent } from "../TableComponent";
import DateRangePickerComponent from "../DateRangePickerComponent";
import type { Props } from "../../containers/Dashboard/types/Dashboard.types";
import type { Column } from "../TableComponent/types/TableComponent.types";
import type { ItemData, TotalData } from "../../services/dashboardApi";

const columns: Array<Column<ItemData>> = [
  {
    field: "date",
    label: "Date",
    key: "date",
    render: ({ row }): string => (formatDate(row.date) === "Invalid Date" ? row.date : formatDate(row.date)),
  },
  {
    field: "quantity",
    label: "Leads",
    key: "quantity",
  },
  {
    field: "cost",
    label: "Average CPL, €",
    key: "cost",
    render: ({ row }): string => `€${row.cost || 0}`,
  },
  {
    field: "amount",
    label: "Total Commission, €",
    key: "amount",
    render: ({ row }): string => `€${row.amount || 0}`,
  },
];
const totalColumns: Array<Column<TotalData>> = [
  {
    field: "",
    label: "",
    key: "total",
    render: (): string => "Total",
  },
  {
    field: "quantity",
    label: "",
    key: "quantity",
  },
  {
    field: "cost",
    label: "",
    key: "cost",
    render: (): string => "-",
  },
  {
    field: "amount",
    label: "",
    sortable: true,
    key: "amount",
    render: ({ row }): string => `€${row.amount || 0}`,
  },
];

const Dashboard: StatelessFunctionalComponent<Props> = ({
  data = [],
  dataTotal,
  filters,
  onGetDashboardData,
  onFiltersChange,
  loading,
}: Props) => {
  useEffect(() => {
    if (data.length === 0) {
      onGetDashboardData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" pb={2}>
          <Typography variant="h3">Dashboard</Typography>
          <Box display="flex">
            <DateRangePickerComponent filters={filters} onFiltersChange={onFiltersChange} />
            <Box pl={1}>
              <Button variant="contained" color="primary" disabled={loading} onClick={onGetDashboardData}>
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
        <Box component={Paper}>
          <Box position="absolute" pt={1} pl={3}>
            <Typography variant="h4">Chart report</Typography>
          </Box>
          <Chart data={data} />
        </Box>
        { loading && (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        { isShowNoDataMessage && (
          <Box mt={3} display="flex" justifyContent="center">
            <Typography>Nothing to show</Typography>
          </Box>
        )}
        { isShowData && (
          <>
            <Box component={Paper} mt={3} p={3}>
              <Box pb={2}>
                <Typography variant="h4">Table report</Typography>
              </Box>
              <TableComponent
                data={data}
                columns={columns}
                totalData={dataTotal}
                totalColumns={totalColumns}
              />
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
