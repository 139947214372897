// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers, applyMiddleware, createStore, compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import {
  loadingReducer as loading,
  initLoadingState,
  type LoadingState,
} from "@fas/cpa-state-manager/redux/reducers/loading";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/cpa-state-manager/redux/reducers/table";
import type {
  LoadingActions,
} from "@fas/cpa-state-manager/redux/actions/loading";
import type {
  Actions as TableActions,
} from "@fas/cpa-state-manager/redux/actions/table";
import dayjs from "dayjs";
import type { Filters } from "@fas/cpa-state-manager/redux/actions";
import dictionaries, {
  initState as initDictionariesState,
  type State as DictionariesState,
} from "./reducers/dictionaries";
import transactionReport, {
  initState as initTransactionReportState,
  type State as TransactionReportState,
} from "./reducers/transactionReport";
import type { Actions as ActiveBrandsActions } from "./actions/activeBrands";
import type { Actions as DictionaryActions } from "./actions/dictionaries";
import type { Actions as AuthActions } from "./actions/auth";
import mainSaga from "./saga";
import {
  DASHBOARD_TABLE, BRANDS_TABLE, TRANSACTION_TABLE,
} from "./helpers/constants";
import { getQueryFilter } from "./helpers/queryFilter";

type LoadingTypes = "getAuthLoading"
  | "getDashboardDataLoading"
  | "getActiveBrandsListLoading"
  | "getTransactionReportLoading"
  | "getDropdownListLoading";

export type State = $ReadOnly<{|
  loading: LoadingState<LoadingTypes>,
  tables: TableState,
  dictionaries: DictionariesState,
  transactionReport: TransactionReportState,
|}>;

export const PERFORMER: string = "Tamtech PTE. LTD.";

const commonTableFilters: Filters = {
  performer: [PERFORMER],
};

export function mapState(): State {
  return {
    loading: initLoadingState({
      getAuthLoading: false,
      getDashboardDataLoading: false,
      getActiveBrandsListLoading: false,
      getDropdownListLoading: false,
    }),
    tables: initTableState({
      [DASHBOARD_TABLE]: {
        filters: {
          ...commonTableFilters,
          dateFrom: dayjs().subtract(1, "M").format("YYYY-MM-DD"),
          dateTo: dayjs().format("YYYY-MM-DD"),
        },
      },
      [BRANDS_TABLE]: {
        pageSize: 15,
        filters: {
          performer: PERFORMER,
        },
      },
      [TRANSACTION_TABLE]: {
        pageSize: 15,
        filters: {
          ...commonTableFilters,
          dateFrom: dayjs().subtract(1, "M").format("YYYY-MM-DD"),
          dateTo: dayjs().format("YYYY-MM-DD"),
          currency: "€",
          dynamicFields: ["customer"],
          ...getQueryFilter(),
        },
      },
    }),
    dictionaries: initDictionariesState(),
    transactionReport: initTransactionReportState(),
  };
}

export type Actions = LoadingActions<string>
  | TableActions
  | ActiveBrandsActions
  | AuthActions
  | DictionaryActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  loading,
  tables,
  dictionaries,
  transactionReport,
});

// $FlowFixMe
const sagaMiddleware: SagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line import/no-anonymous-default-export
export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<
    State,
    Actions,
    Dispatch<Actions>
  >(reducers, mapState(), composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(mainSaga);

  return store;
};
