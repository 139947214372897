// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DateRangeDateCell from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeDateCell";
import type { DateRangeDateCellProps } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";

type Classes = {
  root: string,
  disabled: string,
  startEndSelectedDay: string,
  selectedDay: string,
  otherMonth: string,
};

type StylesProps = {
  weekday: number,
};

const useStyles: (props: StylesProps) => Classes = makeStyles((theme: Theme): { [$Keys<Classes>]: *} => ({
  root: {
    margin: "1px",
    borderRadius: "2px",
    width: "35px",
    height: "34px",
    color: ({ weekday }: StylesProps): string => (
      weekday === 0 || weekday === 6 ? theme.palette.text.secondary : theme.palette.text.primary
    ),
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  startEndSelectedDay: {
    "&:focus.Mui-selected, &.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  selectedDay: {
    "&:focus.Mui-selected, &.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

const DateCellComponent: StatelessFunctionalComponent<DateRangeDateCellProps> = ({
  day,
  ...rest
}: DateRangeDateCellProps) => {
  const weekday: number = day.day();
  const classes: Classes = useStyles({ weekday });

  return (
    <DateRangeDateCell
      {...rest}
      day={day}
      // $FlowFixMe
      classes={classes}
    />
  );
};

export default DateCellComponent;
