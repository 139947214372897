// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  ComposedChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, Area,
} from "recharts";
import type { ItemData } from "../../services/dashboardApi";

export const formatDate: (v: string) => string = (v: string): string => v;
export const formatNumber: (v: number) => string = (v: number): string => (v >= 1000 ? `${v / 1000}k` : String(v));

type Props = {
  data: Array<ItemData>,
}

const getFallbackData: () => ItemData[] = () => [
  { amount: 0, date: "" },
  { amount: 0, date: "" },
];

const commonAreaProps: {[key: string]: mixed } = {
  type: "monotone",
  strokeWidth: 2,
  strokeLinecap: "round",
  activeDot: true,
  isAnimationActive: false,
};

const dot1: Object = {
  dot: {
    r: 4,
    fill: "url(#stroke1)",
    strokeWidth: 3,
    stroke: "white",
    fillOpacity: 1,
  },
};
const dot2: Object = {
  dot: {
    r: 4,
    fill: "url(#stroke2)",
    strokeWidth: 3,
    stroke: "white",
    fillOpacity: 1,
  },
};

const Chart: StatelessFunctionalComponent<Props> = ({
  data,
}: Props) => (
  <ResponsiveContainer width="100%" height="100%" minHeight={350} minWidth={300}>
    <ComposedChart
      data={data.length ? data : getFallbackData()}
      margin={{
        top: 30, right: 60, left: 0, bottom: 30,
      }}
    >
      <CartesianGrid />
      <Tooltip labelFormatter={formatDate} />
      <XAxis dataKey="date" tickFormatter={formatDate} />
      <YAxis tickFormatter={formatNumber} />
      <Legend
        wrapperStyle={{ top: 15, left: 0 }}
        verticalAlign="top"
        align="center"
      />

      <defs>
        <linearGradient id="stroke1">
          <stop stopColor="#8BC34A" />
        </linearGradient>
        <linearGradient id="stroke2">
          <stop stopColor="#F04F23" />
        </linearGradient>
        <linearGradient id="fill1" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8BC34A" stopOpacity={0.2} />
          <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient id="fill2" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#F04F23" stopOpacity={0.2} />
          <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
        </linearGradient>
      </defs>

      <Area {...commonAreaProps} {...dot1} dataKey="amount" name="Commission" stroke="url(#stroke1)" fill="url(#fill1)" />
      <Area {...commonAreaProps} {...dot2} dataKey="quantity" name="Leads" stroke="url(#stroke2)" fill="url(#fill2)" />

    </ComposedChart>
  </ResponsiveContainer>
);

export default Chart;
