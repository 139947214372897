// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import {
  AppNavSidebar as AppNavSidebarComponent,
  AppNavSidebarMenuComponent,
  type AppNavSidebarProps,
  type AppNavSidebarMenuComponentUrl,
  type AppNavSidebarMenuComponentProps,
} from "@fas/cpa-cabinet-ui";
import { type Theme, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Assessment, Home, Star } from "@mui/icons-material";
import PostbackUrl from "../../icons/PostbackUrl";

type Props = {
  isOpen: boolean,
}

const useDrawerStyles: () => $PropertyType<AppNavSidebarProps, "classes"> = makeStyles(() => ({
  paper: {
    justifyContent: "flex-start",
  },
}));

const useMenuListStyles: () => $PropertyType<AppNavSidebarMenuComponentProps, "classes"> = makeStyles((theme: Theme): $PropertyType<AppNavSidebarMenuComponentProps, "classes"> => ({
  menuItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  menuItemIcon: {
    color: "inherit",
    minWidth: "56px !important",
  },
  menuItemText: {
    fontWeight: 600,
    marginTop: "4px !important",
    marginBottom: "4px !important",
    "& span": {
      fontWeight: "inherit",
    },
  },
}));

const useToolbarStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    minHeight: theme.spacing(6),
  },
}));

const AppNavSidebar: StatelessFunctionalComponent<Props> = ({
  isOpen,
}: Props) => {
  const drawerClasses: $PropertyType<AppNavSidebarProps, "classes"> = useDrawerStyles();
  const menuListClasses: $PropertyType<AppNavSidebarMenuComponentProps, "classes"> = useMenuListStyles();
  const toolbarClasses = useToolbarStyles();

  const links: Array<AppNavSidebarMenuComponentUrl> = [
    { url: "/dashboard", text: "Dashboard", icon: <Home /> },
    { url: "/transactionReport", text: "Transaction Report", icon: <Assessment /> },
    { url: "/activeBrands", text: "Active Brands", icon: <Star /> },
    { url: "/postbacks", text: "Postback URLs", icon: <PostbackUrl /> },
  ];

  const Offset = () => <Toolbar className={toolbarClasses.toolbar} />;

  return (
    <AppNavSidebarComponent
      permanentIconsVisible
      isOpen={isOpen}
      classes={drawerClasses}
    >
      <Offset />
      <AppNavSidebarMenuComponent links={links} classes={menuListClasses} />
    </AppNavSidebarComponent>
  );
};

export default AppNavSidebar;
