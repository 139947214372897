// @flow
import dayjs from "dayjs";
import type { StandardProperties as CSSProperties } from "csstype";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { DropDownObjItemType } from "../reducers/dictionaries";
import type { Fields, Filters } from "../services/dashboardApi";
import { getAuthProductCompany } from "../services/request";

export function mapOptions({ value, label }: DropDownObjItemType): { value: string, title: string } {
  return {
    value,
    title: label,
  };
}

export const parsePercentage: (value: number) => string = (value) => (
  Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value));

export const getGroupByDateRange: (string, string) => "day" | "month" | "year" = (from, to) => {
  const diffDays: number = Math.abs(dayjs(to).diff(from, "d"));
  if (diffDays > 365) {
    return "year";
  }
  if (diffDays > 31) {
    return "month";
  }

  return "day";
};

export function formatDateByGroup(date: string, group: "day" | "month" | "year"): string {
  switch (group) {
    case "day": return dayjs(date).format("YYYY-MM-DD");
    case "month": return dayjs(date).format("YYYY-MM");
    case "year": return dayjs(date).format("YYYY");
    default: return date;
  }
}

/**
 * Prepare state filters for api filters
 * @param {Object} filters to convert
 * @returns {Filters} prepared filters to api
 */
export function prepareFilters(filters: Filters): Filters {
  const keyConvertMap: { [string]: (string) => mixed } = {
    dateFrom: (v: string): string => v,
    dateTo: (v: string): string => v,
    invoiceId: (v: string): [number] => [parseFloat(v)],
  };

  function defaultConvert(v: mixed): mixed | Array<mixed> {
    return (Array.isArray(v) ? v : [v]);
  }

  const preparedFilters: Filters = Object.keys(filters).reduce((acc: Filters, key: string): Filters => {
    if (filters[key]) {
      return {
        ...acc,
        [key]: (keyConvertMap[key] || defaultConvert)(filters[key]),
      };
    }
    return acc;
  }, {});

  return {
    ...preparedFilters,
    customer: preparedFilters.customer || getAuthProductCompany(),
    department: preparedFilters.department || [],
  };
}

export function prepareFields(fields: Fields[]): Fields[] {
  const filterField: string[] = ["quantity", "amount", "cost"];
  return fields.filter((item: Fields): boolean => !filterField.includes(item));
}

export function downloadCsv(data: string) {
  const hiddenElement: HTMLAnchorElement = document.createElement("a");
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = `TransactionReport-${dayjs().format("dddd_MMMM_Do_YYYY_H_mm_ss")}.csv`;
  document.body && document.body.appendChild(hiddenElement); // for FF
  hiddenElement.click();
  hiddenElement.remove();
}

/**
 * Presets for date range
 * @type {{start: string, end: string, label: string, value: string}[]}
 */
export const presets: Array<{|end: string, label: string, start: string, value: string|}> = [
  {
    label: "Today",
    value: "today",
    start: dayjs().subtract(1, "s").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Yesterday",
    value: "yesterday",
    start: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
  },
  {
    label: "Last week",
    value: "lastweek",
    start: dayjs().subtract(1, "w").startOf("w").add(1, "d")
      .format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "w").endOf("w").add(1, "d")
      .format("YYYY-MM-DD"),
  },
  {
    label: "Last month",
    value: "lastmonth",
    start: dayjs().subtract(1, "M").startOf("M").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "M").endOf("M").format("YYYY-MM-DD"),
  },
];

type CSSStyleDeclaration = CSSProperties<number, number>
type CSS = $Shape<
  CSSStyleDeclaration
  & {[string]: CSSStyleDeclaration}
  & {[$Keys<CSSStyleDeclaration>]: (*) => $Values<CSSStyleDeclaration>}
>

type R = {
  [string]: CSS,
}

/**
 * Wrap material makeStyles, add type signature
 * @param {Function} styles to pass in the material
 * @returns {Function} get from makeStyles useStyles
 */
export function makeStylesTyped<T: R>(styles: (theme: Theme) => T): (*) => {[$Keys<T>]: string} {
  return makeStyles(styles);
}

export function redirect(url: string) {
  window.location.href = url;
}
