// @flow

const themeDefaultObject: mixed = {
  theme: "default",
  props: {},
  typography: {
    fontFamily: "'Sarala', sans-serif",
    body1: {
      fontSize: "0.875rem",
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    background: {
      default: "#fcfcfc",
    },
    primary: {
      main: "#F04F23",
      light: "#ECEFF6",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FDEDE9",
      contrastText: "#F04F23",
    },
    success: {
      main: "#8BC34A",
    },
    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "rgba(0, 0, 0, 0.5)",
      subtitle: "#4867AA",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiTypography: {
      h3: {
        fontSize: "24px",
        fontWeight: 700,
      },
      h4: {
        fontSize: "18px",
        fontWeight: 700,
      },
      body1: {
        fontSize: "1rem",
      },
    },
    MuiTableCell: {
      root: {
        border: "1px solid #F2F2F2",
        backgroundColor: "#FFFFFF",
        textAlign: "center",
      },
    },
    MuiOutlinedInput: {
      input: {
        paddingTop: "6.5px",
        paddingBottom: "6.5px",
      },
      inputMarginDense: {
        paddingTop: "6.5px",
        paddingBottom: "6.5px",
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: "4px !important", // important override - .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]
        paddingBottom: "4px !important",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#000",
        fontSize: "1rem",
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
        letterSpacing: "0.03em",
      },
      containedPrimary: {
        minWidth: "max-content",
        fontWeight: 700,
        backgroundColor: "#F04F23",
        "&:hover": {
          backgroundColor: "#F04F23CC",
          boxShadow: "none",
        },
        boxShadow: "none",
      },
      containedSecondary: {
        minWidth: "max-content",
        fontWeight: 700,
        "&:hover": {
          backgroundColor: "#FDEDE9CC",
          boxShadow: "none",
        },
        boxShadow: "none",
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        width: "33px",
      },
    },
  },
};

export default themeDefaultObject;
